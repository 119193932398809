import { initializeApp } from 'firebase/app';
import { collection, doc, DocumentSnapshot, getDoc, getFirestore, onSnapshot, QueryDocumentSnapshot, QuerySnapshot, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from 'firebase/auth';
import { changeGraph, createGraph, deleteGraph } from './createGraph';

const firebaseConfig = {
    apiKey: 'AIzaSyDvjLzKwVVZVDd_w1tg-G325waKmSIcXTw',
    authDomain: 'aggregate-data-d4dc8.firebaseapp.com',
    projectId: 'aggregate-data-d4dc8',
    storageBucket: 'aggregate-data-d4dc8.appspot.com',
    messagingSenderId: '691879477203',
    appId: '1:691879477203:web:ad197b7930959d7792f926',
};

const app = initializeApp(firebaseConfig);
let teamName: undefined | string;
const states = [document.getElementById('state-0')!, document.getElementById('state-1')!, document.getElementById('state-2')!];
/**
 * Updates the app's UI state.
 *
 * @param state The new state.
 */
const setState = (state: number) => {
    states.forEach((s, i) => (s.style.display = i === state ? 'block' : 'none'));
};
const auth = getAuth();
const db = getFirestore();
const createState2 = async (resetQuota: boolean) => {
    const user = auth.currentUser!;
    const self = collection(db, 'users');
    const currentUserDoc = doc(self, user.uid);
    if (resetQuota) {
        await setDoc(currentUserDoc, {
            approved: false,
            quota: 0,
            name: user.displayName,
            team: teamName ?? 'untitled',
        });
    }
    const snap = await getDoc(currentUserDoc);
    setState(2);
    teamName = snap.data()!.team;
    const approved = snap.data()!.approved;
    const status = document.getElementById('status')!;
    status.textContent = approved ? 'Approved' : 'Not Approved';
    const nameChangeButton = document.getElementById('change-name')! as HTMLButtonElement;
    nameChangeButton.disabled = approved;
    status.classList[approved ? 'add' : 'remove']('green');
    document.getElementById('not-visible')!.classList[approved ? 'add' : 'remove']('invisible');
    document.getElementById('not-visible-2')!.classList[!approved ? 'add' : 'remove']('invisible');
    document.getElementById('quota')!.textContent = snap.data()!.quota;
    document.getElementById('name')!.textContent = user.displayName!;
    const id = `obama__${user.email?.split('@')[0]}__${user.uid}`;
    document.getElementById('obama')!.textContent = id;
    document.getElementById('obama-2')!.textContent = id;
    document.getElementById('team-name')!.textContent = teamName ?? 'untitled';
};
const removeAuthState = onAuthStateChanged(auth, async (user) => {
    if (user) {
        if (/.*@mamkschools.org$/.test(user.email!) || /.*@student.mamkschools.org$/.test(user.email!) || user.email! == 'ilyastrug@gmail.com' || user.email! == 'tfitz200@gmail.com') {
            const self = collection(db, 'users');
            const currentUserDoc = doc(self, user.uid);
            let snap = await getDoc(currentUserDoc);
            if (window.location.href.includes('live')) {
                createLiveData();
                return;
            }
            createState2(!snap.exists());
            document.getElementById('reset-quota')!.addEventListener('click', () => {
                createState2(true);
            });
            document.getElementById('sign-out')!.addEventListener('click', () => {
                signOut(auth).then(() => window.location.reload());
            });
            document.getElementById('change-name')!.addEventListener('click', () => {
                toggleEdit();
            });
            document.getElementById('change-done')!.addEventListener('click', async () => {
                toggleEdit();
                teamName = (document.getElementById('team-input') as HTMLInputElement).value;
                const user = auth.currentUser!;
                const self = collection(db, 'users');
                const currentUserDoc = doc(self, user.uid);
                await updateDoc(currentUserDoc, {
                    team: teamName,
                });
                document.getElementById('team-name')!.textContent = teamName!;
            });
        } else {
            removeAuthState();
            signOut(auth);
            setState(0);
        }
    } else {
        await signInWithPopup(auth, provider);
        window.location.reload();
    }
});

const toggleEdit = () => {
    document.getElementById('edit-toggle-1')!.classList.toggle('invisible');
    document.getElementById('edit-toggle-2')!.classList.toggle('invisible');
};

/**
 * Creates the default view for the live data page.
 */
const createLiveData = () => {
    setState(2);
    const ref = collection(db, 'public');
    let isFirst = true;
    let previousSnap: undefined | QuerySnapshot;
    const teamList = document.getElementById('team-list')!;
    const createNew = (doc: QueryDocumentSnapshot, frag: Node) => {
        const team = doc.data()!.name;
        const totalPower = doc.data()!.totalPower;
        const teamItem = (document.getElementById('team-view') as HTMLTemplateElement).content.cloneNode(true) as HTMLDivElement;
        teamItem.querySelector('.team-name')!.textContent = team;
        teamItem.querySelector('.power-output')!.textContent = totalPower;
        (teamItem.children[0]! as HTMLElement).dataset.teamId = doc.id;
        // Scuffed hack.
        setTimeout(() => {
            createGraph(doc.id, doc.data()!);
        }, 0);
        frag.appendChild(teamItem);
    };
    onSnapshot(ref, (data) => {
        if (isFirst) {
            const frag = document.createDocumentFragment();
            data.docs.forEach((doc) => {
                createNew(doc, frag);
            });
            teamList.appendChild(frag);
        } else {
            // Diff this with previous snapshot.
            const newElements = data.docs.filter((doc) => !previousSnap!.docs.some((d) => d.id === doc.id));
            newElements.forEach((doc) => {
                createNew(doc, teamList);
            });
            const removedElements = previousSnap!.docs.filter((doc) => !data.docs.some((d) => d.id === doc.id));
            removedElements.forEach((doc) => {
                deleteGraph(doc.id);
                teamList.removeChild(teamList.querySelector(`[data-team-id="${doc.id}"]`) as HTMLElement);
            });
            const changedElements = data.docs.filter((doc) => {
                const oldDoc = previousSnap!.docs.find((d) => d.id === doc.id);
                return (
                    previousSnap!.docs.some((d) => d.id === doc.id) &&
                    (doc.data()!.name !== oldDoc!.data()!.name || doc.data()!.totalPower !== oldDoc!.data()!.totalPower || Object.entries(doc.data()!).length !== Object.entries(oldDoc!.data()!).length)
                );
            });
            console.debug('Diff found %d new, %d removed, %d changed', newElements.length, removedElements.length, changedElements.length);
            changedElements.forEach((doc) => {
                const teamItem = teamList.querySelector(`[data-team-id="${doc.id}"]`) as HTMLElement;
                teamItem.querySelector('.team-name')!.textContent = doc.data()!.name;
                teamItem.querySelector('.power-output')!.textContent = doc.data()!.totalPower;
                changeGraph(doc.id, doc.data()!);
            });
        }
        previousSnap = data;
        isFirst = false;
    });
};
const provider = new GoogleAuthProvider();
